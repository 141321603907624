import React from "react";
import Navlinks from "./Navlinks";

function Header() {
  return (
    <header><Navlinks /></header>
  );
}

export default Header;

